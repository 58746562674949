<template>
    <div class="col-12">
        <div class="grid grid-nogutter my-0 bg-blue-50 shadow-2">
            <div class="col">
                <p class="pl-2 py-1 text-xl my-1 font-semibold text-blue-500 uppercase">
                    {{ (id != null) ? '#'+id+' '+title : title }}
                </p>
            </div>
            <div class="col-fixed" style="width:250px" v-if="showActions">
                <p class="my-1 text-right">
                    <Btn
                        v-if="back != ''"
                        :to='back'
                        label="ATRAS"
                        iconPos='left'
                        icon='fas fa-chevron-left'
                        classBtn='p-button-text p-button-sm'
                    />
                    <Button 
                        v-if="reset != ''"
                        @click="clearForm"
                        label="LIMPIAR" 
                        icon="fas fa-sync"
                        iconPos="left"
                        class="p-button-text p-button-danger p-button-sm" 
                    />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitlePage',
    props:{
        title: {
            type: String,
            default: '',
        },
        back: {
            type: String,
            default: '', 
        },
        reset: {
            type: Boolean,
            default: false,
        },
        id:{
            default: null,
        },
        showActions: {
            type: Boolean,
            default: true
        }
    },

    data(){
        return {
            //title = a,
        }
    },
    methods: {
        clearForm(){
            this.$parent.resetForm()
        }
    }
}
</script>