<template>
    <div class="layout-footer ShowOnDesktop bg-blue-100">
        <div class="flex align-items-center justify-content-between p-2">
            <p class="m-0"><a href="" class="no-underline" target="_blank"></a>V1.0 {{environment}}</p>
            <p class="text-green-600 m-0" v-if="state">API Online</p>
            <p class="text-pink-600 m-0" v-if="!state">API Offline</p>
        </div>
    </div>
</template>

<style scoped>
    .layout-footer {
        position: fixed;
        width: 100%;
        bottom: 0;
    }
</style>

<script>
    export default {
        name: 'Footer',
        props: {
            state: Boolean
        },

        data(){
            return {
                environment: ''
            }
        },

        mounted(){
            this.environment = (process.env.VUE_APP_ENV_AMBIENT) 
                ? process.env.VUE_APP_ENV_AMBIENT
                : ''
        }
    }
</script>