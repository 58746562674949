const formatValues = {
    CUIT(cuit){
        if(cuit){
            return  cuit.slice(0, 2) + '-' + cuit.slice(2, cuit.length - 1) + '-' + cuit.slice(-1);
        }
        return "";
    },

    Currency(amount, currency = 'ARS'){
        var opts = null;
        
        switch(currency){
            case 'ARS': 

                opts = Intl.NumberFormat("es-AR", {
                    style: "currency",
                    currency: "ARS",
                });

                break;

            case 'USD':
                
                opts = Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                });

            break;
        }

        amount = (!isNaN(amount)) ? amount : 0

        return (opts) ? opts.format(amount) : amount;
    }
}

export default formatValues