const globalMethods = {
    getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, (object[key]) ? object[key] : ''));
        return formData;
    },
    getDeviceType(){
        var ua = navigator.userAgent;
        if (ua.match(/Android/i)) return "Android";
        else if (ua.match(/BlackBerry/i)) return "BlackBerry";
        else if (ua.match(/iPhone|iPad|iPod/i)) return "iOS";
        else if (ua.match(/Opera Mini/i)) return "Opera Mini";
        else if (ua.match(/IEMobile/i)) return "Windows Phone";
        else if (ua.match(/Linux/i)) return "Linux";
        else if (ua.match(/Windows/i)) return "Windows";
        else return "Apple";
    },
}

export default globalMethods