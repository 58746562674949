import design from './formatValues.js'
import defaultData from './defaultData.js'
import globalMethods from './globalMethods.js'
import strings from './strings.js'

const helpers = {
    created() {
        this.defaultData = defaultData
        this.globalMethods = globalMethods
        this.design = design
        this.strings = strings
    },
    methods: {
        FormRequestFail: function(e = "", custom = false){
            this.$toast.add({
                severity:'error', 
                summary: 'Existen Errores', 
                detail: (custom) ? e : 'Se produjo un error en la solicitud. ', 
                life: 3000
            });
        },
        FormErrors: function (result) {
            if(typeof result == 'object'){
                let error = JSON.parse(result.response.data);
                let error_list = [].concat.apply([], Object.values(error));
                
                if(Array.isArray(error_list)){
                    for (let i = 0; i < error_list.length; i++) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Existen Errores', 
                            detail: error_list[i], 
                            life: 3000
                        });
                    }
                }
            }else{
                this.$toast.add({
                    severity:'error', 
                    summary: 'Existen Errores', 
                    detail:  result.response.data.error, 
                    life: 3000
                });
            }
        },
        FormSuccess: function(msj){
            this.$toast.add({
                severity:'success', 
                summary: 'Completado', 
                detail: msj, 
                life: 3000
            });
        },
    },
  }
  
export default helpers