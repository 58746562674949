<template>
    <div class="text-center">
        <ProgressSpinner 
            animationDuration="5" 
            style="overflow: hidden; width:50px; height:50px;"/>
    </div>
</template>

<style scoped>
    .p-progress-spinner{
        width: 100%;
        text-align: center !important;
    }
</style>

<script>
    import ProgressSpinner from 'primevue/progressspinner';
export default {
    components:{
        ProgressSpinner
    }
}
</script>