<template>
    <Dialog 
        header="Atención" 
        :style="{width: '50vw'}" 
        :modal="true"
        v-model:visible="display"
        :closable="false"
        :closeOnEscape="false">
        <div>
            <Message severity="warn" :closable="false">
                El registro al que intenta acceder no existe.
            </Message>                    
        </div>
        <template #footer>
            <Btn
                to="/"
                label='IR AL INICIO'
                classBtn='p-button-sm'
            />
        </template>
    </Dialog>
</template>

<script>
    export default {
        name: 'MissingRecord',
        data(){
            return{
                display: true,
            }
        }
    }
</script>