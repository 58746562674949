<template>
      <router-link 
        v-bind:to="to" 
        tag="Button"
        class="no-underline cursor-pointer"
        :target="blank ? '_blank' : ''"
      >
        <Button 
          v-bind:class="classBtn"
          v-bind:label="label" 
          v-bind:iconPos="iconPos"
          v-bind:icon="icon"
        />
      </router-link>
</template>

<script>
export default {
    name: 'Btn',
    props:{
      to: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      iconPos: {
        type: String,
        default: '',
      },
      classBtn: {
        type: String,
        default: '',
      },
      blank: {
        type: Boolean,
        default: false
      }
    },
}
</script>

<style>
  .p-button-label{
    padding-left: 7px !important;
  }
</style>