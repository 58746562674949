const defaultData = {

    comboStates : [
        { value: 0, label: "Inactivo"   },
        { value: 1, label: "Activo"     },
    ],
    comboFPago : [
        { value: 0, label: "Contado"   },
        { value: 1, label: "Transferencia"     },
        { value: 1, label: "Paga en Playón"     },
    ],
}

export default defaultData