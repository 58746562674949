// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core"
import { dom } from '@fortawesome/fontawesome-svg-core'

import {
    faHome, faUserCog, faEdit,
    faUser, faList, faTrash,
    faPlus, faBoxes, 
    faLayerGroup, faUserTag,
    faTag, faShapes,
    faPeopleArrows, faShoppingCart, 
    faBars, faCog, faSignOutAlt, faSignInAlt,
    faPrint, faChevronLeft, faChevronRight, faSync, 
    faEye,faTimes, faTools, faImage, faKey, 
    faTruck, faCheck, faSave, faDollarSign, 
    faFileAlt, faClock, faLockOpen, faHistory, faSearch, faTruckMoving, faClipboardList,
    faExternalLinkAlt, faFileCsv, faCalendar, faLock, faChartLine, faBell, faComment,
    faUsers, faDonate, faCoins, faMapMarkerAlt, faFilePdf, faExclamationTriangle, faChevronUp
} from "@fortawesome/free-solid-svg-icons"

library.add( 
    faHome, faUserCog, faUser, 
    faList, faPlus, faBoxes, 
    faLayerGroup, faUserTag, faTag, 
    faShapes, faPeopleArrows, faShoppingCart, 
    faBars, faCog, faSignOutAlt, 
    faSignInAlt, faPrint, faEdit, 
    faTrash, faChevronLeft, faChevronRight,
    faSync, faEye, faTimes, faTools, faImage, 
    faKey, faTruck, faCheck, faSave, faDollarSign, 
    faFileAlt, faClock, faLockOpen, faHistory, faSearch, faTruckMoving, faClipboardList,
    faExternalLinkAlt, faFileCsv, faCalendar, faLock, faChartLine, faBell, faComment,
    faUsers, faDonate, faCoins, faMapMarkerAlt, faFilePdf, faExclamationTriangle, faChevronUp
)

dom.watch() 


export default FontAwesomeIcon