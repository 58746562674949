<template>
  <div>
    <Header/>

    <router-view/>
    
    <ConfirmDialog></ConfirmDialog>

    <Toast position="bottom-left" />
    <Footer v-if="status != null" :state="status" />
  </div>
</template>


<script>

import apiService from './services/apiService.js'

import Header from './components/templates/Header.vue'
import Footer from './components/templates/Footer.vue'

export default {
  components: {
    Header,
    Footer
  },
  data(){
    return{
      status: null,
      items: [],
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.isAuthenticated;
    },
  },

  created() {
    this.APIStatus()

    if (!this.loggedIn) {
      return this.$router.push("/login");
    }
  },

  methods: {
    async APIStatus () {
      await apiService.API.status()
      .then((result) => {
          this.status =  (result) ? true : false
      }).catch(() => {
          this.status = false
      })
    },
  }

}
</script>