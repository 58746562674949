import { createRouter, createWebHistory } from 'vue-router'
import storage from '../storage'

const routes = [
  {
    path: '/:catchAll(.*)*',
    name: 'Inicio',
    component: () => import('../views/Inicio.vue')
  },{ 
    path: '/', 
    component: () => import('../views/Inicio.vue'), 
  },{
    path: '/401',
    name: 'Error 401',
    component: () => import('../views/Errors/401.vue')
  },{
    path: '/home',
    name: 'Inicio',
    component: () => import('../views/Inicio.vue')
  },{
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },

  // Cuenta
  {
    path: '/account',
    name: 'Cuenta',
    component: () => import('../views/Account/Index.vue')
  },

  // Configuracion Modulo
  {
    path: '/support',
    name: 'Asistencia',
    component: () => import('../views/Assistance/Index.vue')
  },{
    path: '/support/:id',
    name: 'Detalles Consulta',
    component: () => import('../views/Assistance/Detail.vue')
  },
  
  // Usuarios
  {
    path: '/users',
    name: 'Usuarios',
    component: () => import('../views/Users/Index.vue')
  },{
    path: '/users/create',
    name: 'Crear Usuario',
    component: () => import('../views/Users/Create.vue')
  },{
    path: '/users/:id',
    name: 'Editar Usuario',
    component: () => import('../views/Users/Edit.vue')
  },

  // Configuracion
  {
    path: '/places',
    name: 'Ubicaciones',
    component: () => import('../views/Places/Index.vue')
  },
  
  // Gestion
  {
    path: '/reports',
    name: 'Reportes',
    component: () => import('../views/Reports/Index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ENV_BASE_URL),
  routes
})

// Manejo de acceso no autorizado.

router.beforeEach((to, from, next) => {
  if('/login' != to.path){

    if(!storage.state.isAuthenticated || !storage.state.allowed){
      if (to.path !== '/login') { 
        return storage.commit('logout') 
      }
    }
    
    const toClean = '/' + to.path.split('/')[1];
    const isAllowed   = storage.state.allowed.includes(toClean);

    if(!isAllowed){
      if (to.path !== '/401' && to.path !== '/home') { return next('/401') }
    }
  }
  
  document.title = (to.name) ? to.name : 'Reservas';
  next();
});

export default router
