require('./utils/bootstrap')

import { createApp } from 'vue'

// Rutas
import router from './router'

// Storage Persistent
import storage from './storage'

// Componente principal.
import App from './App.vue'

// Definiciones Globales
import Helpers from './helpers'

// Font Awesome
import FontAwesomeIcon from './utils/icons'

import moment from 'moment-timezone'
moment.tz.setDefault('America/Argentina/Buenos_Aires')

//mitt
import mitt from 'mitt'

const emitter = mitt()

// Prime Vue
import PrimeVue from 'primevue/config'

//Navbar
import Sidebar from 'primevue/sidebar'
import Toolbar from 'primevue/toolbar'
import Listbox from 'primevue/listbox'
import Tree from 'primevue/tree'

// Toast
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'

// Confirmation
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'
import Dialog from 'primevue/dialog'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
// Form
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'

import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Card from 'primevue/card'
import InputNumber from 'primevue/inputnumber'

import AutoComplete from 'primevue/autocomplete'
import MultiSelect from 'primevue/multiselect'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'

// Datatable
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Panel from 'primevue/panel'

// Custom
import Textarea from 'primevue/textarea'
import TitlePage from './components/templates/TitlePage.vue'
import MissingRecord from './components/templates/MissingRecord.vue'
import Loading from './components/templates/Loading.vue'
import NoResult from './components/templates/NoResult'
import Btn from './components/forms/Btn'
import NightPicker from './components/forms/NightPicker'

import 'primevue/resources/themes/saga-blue/theme.css'              //Theme
import 'primevue/resources/primevue.min.css'                        //Core CSS
import 'primeicons/primeicons.css'                                  //Icons

import "primeflex/primeflex.css"                                   //Prime Flex
import './assets/css/app.css'                                       // CSS

// Plugins
import VueHtmlToPaper from "./plugins/VueHtmlToPaper"

const app = createApp(App)                                          // Instancia

app.config.globalProperties.$moment=moment
app.config.globalProperties.emitter = emitter

app.use(router)
//app.use(VueAxios, axios)
app.use(storage)
app.use(PrimeVue, {
    locale: {
        accept: 'Aceptar',
        reject: 'Rechazar',
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
        monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mier", "Jue", "Vier", "Sab"],
        today: 'Hoy',
        weekHeader: 'Sem',
        firstDayOfWeek: 0,
        dateFormat: 'dd-mm-yy',
    }
})
app.use(ToastService)
app.use(ConfirmationService)
app.use(VueHtmlToPaper)
app.mixin(Helpers)

// Componentes

app.component('font-awesome-icon', FontAwesomeIcon)

app.component('Btn', Btn)
app.component('NightPicker', NightPicker)

app.component('Loading', Loading)
app.component('NoResult', NoResult)
app.component('TitlePage', TitlePage)
app.component('MissingRecord', MissingRecord)

app.component('Button', Button)
app.component('SplitButton', SplitButton)
app.component('InputText', InputText)
app.component('Checkbox', Checkbox)
app.component('InputNumber', InputNumber)
app.component('AutoComplete', AutoComplete)
app.component('Dropdown', Dropdown)
app.component('MultiSelect', MultiSelect)
app.component('Textarea', Textarea)
app.component('Calendar', Calendar)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Dialog', Dialog)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Toast', Toast)
app.component('Sidebar', Sidebar)
app.component('Toolbar', Toolbar)
app.component('Listbox', Listbox)
app.component('Tree', Tree)
app.component('Panel', Panel)
app.component('Card', Card)

// Custom
app.mount("#app")

console.info("Ambient: ", process.env.VUE_APP_ENV_AMBIENT)
//console.info("BaseURL: ", process.env.VUE_APP_ENV_BASE_URL)