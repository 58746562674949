<template>
    <Dropdown 
      v-if="!multiple" 
      v-model="selectDate"
      :options="nights" 
      optionLabel="name" 
      @change="handleChange($event)"
      optionValue="value" 
      class="w-full"
      placeholder="Seleccionar Noche" />

    <MultiSelect 
      v-if="multiple" 
      v-model="selectDate"
      :options="nights" 
      optionLabel="name" 
      :filter="true"
      @change="handleChange($event)"
      optionValue="value" 
      class="w-full"
      placeholder="Seleccionar Noche" />

      <ConfirmPopup group="headless" />
</template>

<script>
  import ConfirmPopup from 'primevue/confirmpopup';

export default {
  name: 'NightPicker',
  components: {
    ConfirmPopup
  },
  props:{
    multiple: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["handleChange"],
  data(){
      return { 
        selectDate: this.multiple ? [] : null,
        nights: []
      }
    },
    mounted(){
        this.nights = JSON.parse(this.$store.state.nights)

        let date = localStorage.getItem('date')

        if(!this.multiple){
          this.selectDate = date ? date : this.nights[0].value 
        }
    },

    methods: {
        handleChange(event){
          let multiple = JSON.parse(localStorage.getItem('multiReservation'));

          if(multiple && multiple.status){
            this.$confirm.require({
              target: event.currentTarget,
              message: 'Si modifica la noche, no se guardaran las reservas multiples seleccionadas. ¿Continuar de todos modos?',
              icon: 'fas fa-exclamation-triangle',
              accept: () => {
                this.$emit('handleChange', this.selectDate);
              },
              reject: () => {
                let date = localStorage.getItem('date');
                if(date){
                  this.selectDate = date;
                }
              }
            })
          }else{
            this.$emit('handleChange', this.selectDate);
          }
        },
    }
}
</script>

<style>
.p-button-label{
  padding-left: 7px !important;
}
</style>