<template>
    <div>
        <div class="col-12 mt-5 text-center">
            <span class="text-pink-600 p-text-light text-2xl">SIN RESULTADOS PARA MOSTRAR</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoResult'
}
</script>