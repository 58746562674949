import axios from 'axios'
import storage from '../storage'

function getError(error){
  if(error.response.status == 401){
      storage.commit('logout')
    }else if(!error.request.status){
      console.log("API Offline")
      return false
    }
    return error
}

export default {

  API: {
    async status(){
      return await axios.get('/status').then((response) => {
        return (response.data) ? true : false
      }).catch((error) => {
        return getError(error)
      })
    },
  },

  Auth: {
    async login(payload){
      return await axios.post('/auth/login', payload)
      .then(response => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
  },

  Menu: {
    async get(){
      return await axios.get('/menu').then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
  },

  Assistance: {
    async get(id = ""){
      var route = (id != "") ? '/assistance/get/' + id : '/assistance/get';
      return await axios.get(route).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async store(payload){
      return await axios.post('/assistance/store', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async storeComment(payload){
      return await axios.post('/assistance/comment', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async close(id){
      return await axios.post('/assistance/close', { id: id }).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async reasons(id = ""){
      var route = (id != "") ? '/assistance/reasons/' + id : '/assistance/reasons';
      return await axios.get(route).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
  },

  Catalog: {
    async province(id = ""){
      var route = (id != "") ? 'catalog/province/'+id : '/catalog/province';
      return await axios.get(route).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async city(term = ""){
      return await axios.get('catalog/city/' + term).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async gender(){
      return await axios.get('catalog/gender').then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async user(){
      return await axios.get('/catalog/user').then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async nights(){
      return await axios.get('/catalog/nights').then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async nightsStatusPlace(place){
      return await axios.get('/catalog/nights/status/'+place).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
  },

  User: {
    async get(id = ""){
      var route = (id != "") ? '/user/get/' + id : '/user/get';
      return await axios.get(route).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async store(payload){
      return await axios.post('/user/store', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async update(payload){
      return await axios.post('/user/update', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async delete(id){
      let payload = { 'id': id }
      return await axios.post('/user/delete', payload).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async resetPwd(id){
      let payload = { 'id': id }
      return await axios.post('/user/resetpwd', payload).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async updateProfile(payload){
      return await axios.post('/user/updateprofile', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async updateCredentials(payload){
      return await axios.post('/user/updatecredentials', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async logs(id = ""){
      return await axios.get((id != "") ? '/user/logs/'+id : '/user/logs')
      .then(response => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async generatePwd(){
      return await axios.get('user/pwd/generate').then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
  },

  Role: {
    async get(id = ""){
      var route = (id != "") ? '/role/get/' + id : '/role/get';
      return await axios.get(route).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async delete(id){
      let payload = { 'id': id }
      return await axios.post('/role/delete', payload).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    }
  },


  Group: {
    async get(){
      return await axios.get('/group').then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async dashboard(date){
      return await axios.get('/group/dash/' + date).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async show(id){
      return await axios.get('/group/' + id).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
  },
  Place: {
    async all(){
      return await axios.get('/place').then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async show(id){
      return await axios.get('/place/' + id).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async status(id, date){
      return await axios.get('/place/status/' + id + '/' + date).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async update(id, payload){
      return await axios.put('/place/' + id, payload).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
    async reference(payload){
      return await axios.post('/place/reference', payload).then((response) => {
        return response.data
      }).catch((error) => {
        return getError(error)
      })
    },
  },

  Reservation: {
    async start(payload){
      return await axios.post('/reservation', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async end(id){
      return await axios.post('/reservation/end/'+id).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async filter(filter){
      return await axios.post('/reservation/filter',filter).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async update(payload){
      return await axios.post('/reservation/update', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async prints(request){
      return await axios.post('/reservation/prints', request).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async startMultiple(payload){
      return await axios.post('/reservation/multiple', payload).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
    async ticketMultiple(uuid){
      return await axios.post('/reservation/ticket-group/'+uuid).then((response) => {
        return response.data
      }).catch((error) => {
          return getError(error)
      })
    },
  }
}