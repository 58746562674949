import storage from '../storage'
import axios from 'axios'

window.axios = axios;

axios.defaults.baseURL = process.env.VUE_APP_ENV_HOST
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

if(storage.state.isAuthenticated){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + storage.state.token;
}