import { createStore } from 'vuex'
import router from '../router'
import VuexPersistence  from 'vuex-persist'
import axios from 'axios'

const storage = createStore({
    state: {
        isAuthenticated: false,
        user: [],
        token: null,
        allowed: [],
        structure: [],
        nights: []
    },

    mutations: {
        setAuthenticated(state, value){
            state.isAuthenticated = value
        },
        setUserData(state, user){
            state.user = user
        },
        setAccessAllow(state, menu){
            state.structure = JSON.stringify(menu.structure)
            state.allowed = Object.values(menu.allowed)
        },
        setToken(state, access_token){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
            state.token = access_token
        },
        setNights(state, nights){
            state.nights = JSON.stringify(nights)
        },
        logout(state){
            state.isAuthenticated = false
            state.token = null
            state.allowed = []
            state.structure = []
            state.user = []
            state.nights = []
            router.push("/login")
        },
    },
    plugins:[
        new VuexPersistence({
            storage: window.localStorage
        }).plugin
    ]
})

export default storage