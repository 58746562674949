<template>
    <div>
        <Toolbar>
            <template #start>
                <Button 
                    v-if="this.$store.state.isAuthenticated" 
                    icon="fas fa-bars" 
                    iconPos="center" 
                    @click="toggleMenu()" />
            </template>

            <template #end> 
                <Btn
                    v-if="this.$store.state.isAuthenticated" 
                    to='/home'
                    iconPos='center'
                    icon='fas fa-home'
                    classBtn=''
                />

                <SplitButton 
                v-if="this.$store.state.isAuthenticated" 
                label="Cuenta" 
                icon="fas fa-user" 
                @click="$router.push({ path: '/account'})"
                :model="itemsFixed"></SplitButton>
                
                <Btn
                    v-if="!this.$store.state.isAuthenticated" 
                    to='/login'
                    label='Acceder'
                    iconPos='left'
                    icon='fas fa-sign-in-alt'
                    classBtn=''
                />
            </template>
        </Toolbar>

        <Sidebar 
            v-if="this.$store.state.isAuthenticated"
            v-model:visible="visible" 
            :ariaCloseLabel="''"
            class="p-sidebar-sm width-sb"
            >
                <div class="text-white text-center">
                    <span class="text-2xl text-right">
                        Hola, {{$store.state.user.first_name}} !
                    </span>
                </div>
                
                <hr>

                <Tree :value="items" selectionMode="single" :metaKeySelection="false" @node-select="expandNode" :expandedKeys="expandedKeys">
                    <template #default="slotProps">
                        <label class="uppercase text-white no-underline">{{slotProps.node.label}}</label>
                    </template>
                    <template #url="slotProps">
                        <label class="uppercase text-white no-underline">{{slotProps.node.label}}</label>
                    </template>
                </Tree>
                <!-- 
                    <Listbox :options="items" optionLabel="label" @click="toggleMenu()">
                        <template #option="item">
                            <div class="item-header flex align-items-center" @click="$router.push({ path: item.option.url})">
                                <div style="width:35px;">
                                    <i :class="item.option.icon" class="fa-lg"></i>
                                </div>
                                <div class="text-white text-2lg" style="text-transform:uppercase;">
                                    {{ item.option.label }}
                                </div>
                            </div>
                        </template>
                    </Listbox>
                -->
        </Sidebar>
    </div>
</template>

<style>

    .p-tree{
        background-color:#2196f3 !important;
        border: none !important;
        padding: 0 !important;
    }

    .p-treenode{
        font-size: 18px !important;
        margin-bottom: 10px;
        font-weight: 400;
    }

    .p-treenode-icon{
        color: white !important;
    }

    .p-treenode-content:hover{
        background: #2e6fb0 !important;
        cursor:pointer;
    }

    .width-sb{
        width: 250px !important;
    }

    .p-sidebar, .p-toolbar{
        background-color:#2196f3 !important;
    }

    .p-toolbar{
        padding: 5px !important;
    }
    .p-button-label{
        padding-left: 1px;
        padding-right: 5px;
    }
    .p-listbox-item{
        color: white !important;
        margin-top: 5px !important;
        height: 50px;
    }
    .p-listbox-item:hover{
        background: #0b7ad1 !important;
        padding-left: 15px !important;
    }
</style>

<script>
    export default {
        name: 'Header',
        data(){
            return{
                loginStatus: false,
                visible: false,
                expandedKeys: {},
                items: null,
                itemsFixed: [
                    {
                        label: 'Asistencia',
                        icon: 'fas fa-cog',
                        to: '/support'
                    },
                    {
                        label: 'Salir',
                        icon: 'fas fa-sign-out-alt',
                        command: () => {
                            this.$store.commit('logout')
                        }
                    },
                ],
            }
        },

        mounted(){
            if(this.$store.state.isAuthenticated){
                this.items = JSON.parse(this.$store.state.structure)
            }
        },

        methods: {
            toggleMenu(){
                this.visible = !this.visible
            },
            expandNode(node, recursive) {
                this.expandedKeys[node.key] = true;
                if (node.children && node.children.length) {
                    for (let child of node.children) {
                        this.expandNode(child, true);
                    }
                }else{
                    if(node.type && !recursive){
                        this.toggleMenu();
                        this.$router.push(node.data);
                    }
                }
            }
        }
    }

</script>